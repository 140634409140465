import envVar, { setEnvironments } from '@availity/env-var';

const environments = {
  local: ['127.0.0.1', 'localhost'],
  test: [/admin-dev.leadarena.com/, /admin-dev.leadtopia.com/],
  stage: [/admin-stage.ushamarketplace.com/],
  beta: [/admin-beta.ushamarketplace.com/],
  darkpod: [/admin-darkpod.leadarena.com/],
  prod: [
    /admin.leadarena.com/,
    /admin.ushamarketplace.com/,
    /admin.leadtopia.com/,
  ],
};

setEnvironments(environments, true);

const devAwsMobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket:
    'sonic-marketplace-20190414012556-hostingbucket-dev',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://dgarhdt3o9zlk.cloudfront.net',
  aws_cognito_identity_pool_id:
    'us-east-1:1c500333-d67b-40fe-b596-4d22eb319ee2',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_VxaRaMrPy',
  aws_user_pools_web_client_id: '1d3nppplk4sqp1ba1coten94s0',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'admin-area',
      endpoint: 'https://2fo5gtgkh6.execute-api.us-east-1.amazonaws.com/dev',
      region: 'us-east-1',
    },
  ],
};

const prodAwsMobile = {
  aws_project_region: 'us-east-1',
  aws_content_delivery_bucket:
    'sonic-marketplace-20190414012556-hostingbucket-prod',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d9uoo0d8d1rlk.cloudfront.net',
  aws_cognito_identity_pool_id:
    'us-east-1:80f5c276-cd8a-48fc-ac14-c31df7533667',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_SWmFzvnku',
  aws_user_pools_web_client_id: '5dromsrnopienmqa83ba4n927h',
  oauth: {},
  aws_cloud_logic_custom: [
    {
      name: 'admin area',
      endpoint: 'https://poh3jvlrri.execute-api.us-east-1.amazonaws.com/admin',
      region: 'us-east-1',
    },
  ],
};

const vars = envVar(
  {
    local: {
      uri: '/graphql',
      env: 'dev',
      subScriptionUri: 'wss://subscriptions-dev.leadarena.com/subscriptions',
      sonicStripeKey: 'pk_test_ej9Wqulrfr6LgzmKMd6AMgnN',
      ushaStripeKey: 'pk_test_Tcg7G4Tx2RVI94ohrl5AkhYW00sNTJV2j3',
      AUTO_FUND_MINIMUM_AMOUNT: 2000,
      AUTO_FUND_MINIMUM_THRESHOLD: 0,
      awsmobile: devAwsMobile,
      bulkUploadBucket: `leads-aged-leadarena-dev-v2`,
    },
    stage: {
      uri: 'https://40tykjcfq5.execute-api.us-east-1.amazonaws.com/stage/admin',
      subScriptionUri: 'wss://subscriptions-dev.leadarena.com/subscriptions',
      env: 'dev',
      sonicStripeKey: 'pk_test_ej9Wqulrfr6LgzmKMd6AMgnN',
      ushaStripeKey: 'pk_test_Tcg7G4Tx2RVI94ohrl5AkhYW00sNTJV2j3',
      AUTO_FUND_MINIMUM_AMOUNT: 2000,
      AUTO_FUND_MINIMUM_THRESHOLD: 0,
      awsmobile: devAwsMobile,
      bulkUploadBucket: `leads-aged-leadarena-dev-v2`,
    },
    beta: {
      uri: 'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/beta/admin',
      env: 'prod',
      subScriptionUri: 'wss://subscriptions-prod.leadarena.com/subscriptions',
      sonicStripeKey: 'pk_live_1tOiKHMKJLf43wto6nytPRkY',
      ushaStripeKey: 'pk_live_Q3RPHzB6abBKpB0xVDu1yCOq00tZWEsvWq',
      AUTO_FUND_MINIMUM_AMOUNT: 2000,
      AUTO_FUND_MINIMUM_THRESHOLD: 0,
      awsmobile: prodAwsMobile,
      bulkUploadBucket: `leads-aged-leadarena-prod-v2`,
    },
    prod: {
      uri: 'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/prod/admin',
      env: 'prod',
      subScriptionUri: 'wss://subscriptions-prod.leadarena.com/subscriptions',
      sonicStripeKey: 'pk_live_1tOiKHMKJLf43wto6nytPRkY',
      ushaStripeKey: 'pk_live_Q3RPHzB6abBKpB0xVDu1yCOq00tZWEsvWq',
      AUTO_FUND_MINIMUM_AMOUNT: 2000,
      AUTO_FUND_MINIMUM_THRESHOLD: 0,
      awsmobile: prodAwsMobile,
      bulkUploadBucket: `leads-aged-leadarena-prod-v2`,
    },
    darkpod: {
      uri:
        'https://f7sexx0nb0.execute-api.us-east-1.amazonaws.com/darkpod/admin',
      env: 'prod',
      subScriptionUri: 'wss://subscriptions-prod.leadarena.com/subscriptions',
      sonicStripeKey: 'pk_live_1tOiKHMKJLf43wto6nytPRkY',
      ushaStripeKey: 'pk_live_Q3RPHzB6abBKpB0xVDu1yCOq00tZWEsvWq',
      AUTO_FUND_MINIMUM_AMOUNT: 2000,
      AUTO_FUND_MINIMUM_THRESHOLD: 0,
      awsmobile: prodAwsMobile,
      bulkUploadBucket: `leads-aged-leadarena-prod-v2`,
    },
  } as any,
  undefined,
  {
    uri: 'https://40tykjcfq5.execute-api.us-east-1.amazonaws.com/dev/admin',
    subScriptionUri: 'wss://subscriptions-dev.leadarena.com/subscriptions',
    env: 'dev',
    sonicStripeKey: 'pk_test_ej9Wqulrfr6LgzmKMd6AMgnN',
    ushaStripeKey: 'pk_test_Tcg7G4Tx2RVI94ohrl5AkhYW00sNTJV2j3',
    AUTO_FUND_MINIMUM_AMOUNT: 2000,
    AUTO_FUND_MINIMUM_THRESHOLD: 0,
    awsmobile: devAwsMobile,
    bulkUploadBucket: `leads-aged-leadarena-dev-v2`,
  }
);

export default vars;
